@import "src/styles/variables";

.bottom-nav {
  position: sticky;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  border-top: 2px solid $borderColor;
  background: $lavender;
  backdrop-filter: blur(15px);
}

.bottom-nav-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 16%;

  .bottom-nav-item-border{
    background: $gradientBlue;
    padding: 2px;
    border-radius: 8px;
    width: 100%;
  }


  .bottom-nav-item {
    display: flex;
    height: 42px;
    padding: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: $softLavender;
  }

  p {
    color: $brownishGray;
    font-family: $fGolos;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
  }
}

.bottom-nav-item-border.active {
  background: $gradientPink;
}
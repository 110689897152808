@import "src/styles/variables";

.container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px
}

.astro{
  
  height: 43%;
}

.btnContainer{
  width: 100%;
}

.rotating_astro_circle_aries {
  position: absolute;
  animation: rotate-aries 2s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}



@keyframes rotate-aries {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
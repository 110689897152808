@import "src/styles/variables";

.container {
  // background-color: $blue;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;

}

.content {
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}









.itemContainer {
  background: rgba(23, 37, 54, 0.02);
  backdrop-filter: blur(3px);
}


.mainContainer {
  // margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  font-family: $fLora;
  color: $brownishGray;
  font-weight: 700;
  font-size: 18px;

}

.main {
  height: 100dvh;
  background-color: $blue;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }
}
@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: $brownishGray;
  color: $brownishGray;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
  outline: none;
}

html, body {
  overscroll-behavior: none;
  background-color: #E1DEF805;
}

.flex {
  display: flex;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-start {
 justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
 justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

a {
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6 {
  color: $brownishGray;
  font-family: $fGolos;
  margin: 0;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

.text {
  text-align: center;
  font-family: $fLora;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $brownishGray;
}

.input {
  width: 100% ;
  border-radius: 30px;
  border: 2px solid $borderColor;
  background:  $inputBg;
  padding: 16px 16px;
  color: $brownishGray;
  font-family: $fRoboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &::placeholder {
    color: #a1a1a1;
    font-family: $fRoboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:focus {
    caret-color: $brownishGray;
    outline: none;
    border: 2px solid #777777;
  }
}

.btn {
  display: inline-block;
  padding: 14px 32px;
  width: 100%;
  border-radius: 30px;
  border: none;
  color: $white;
  background: $gradientPink;
  text-align: center;
  font-family: $fMontserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hover:hover {
  box-shadow: 0 0 12px 1px rgba($lightPink, 0.7519);
  
}

.disabledBtn {
  background: $disabled;
  cursor: not-allowed !important;
}

.yellow-text {
  color: $yellow;
}

.errorMessage {
  font-family: $fMontserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  color: $error;
  margin-top: 6px;
  text-align: left;
  position: absolute;
}

.linkBtn {
  background: #F86557; /* Fallback color */
  background: linear-gradient(47.6deg, #F86557 -11.64%, #FFB6C1 131.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 5;
  border: none;
  font-family: $fLora;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}


.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  max-width: 430px;
  justify-content: center;
  margin: auto;
  overscroll-behavior: none;
}

.app-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  padding: 24px 0;
  max-width: 430px;
  justify-content: center;
  margin: auto;
  overscroll-behavior: none;
}

@media screen and (max-width: 360px) {
  .container {
    padding: 24px 10px 0;
  }

  .palm-container {
    padding: 0;
  }
}

@media screen and (min-width: 361px) {
  .container {
    padding: 24px 20px 0;
  }

  .palm-container {
    padding: 0 0 20px 0;
  }
}

@media screen and (min-width: 431px) {
  .container, .app-container {
    border-radius: 12px;
    border: 2px solid var(--Borders, #D3D3D3);
    background: #E1DEF805;
    backdrop-filter: blur(2px);
    max-height: 852px;
    max-width: 393px;
    overflow: scroll;
  }

  .app-container {
    padding: 24px 0 0;
  }
}

#three-container {
  position: fixed;
  overflow: hidden;
  z-index: -50;
  background-color: #EDECF7;
}

.bottom-container-absolute {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.bottom-container-relative {
  margin-top: 32px;
  padding-bottom: 40px;
}

.title {
  margin-bottom: 8px;
}

.input-error {
  border: 2px solid $error;
}

.circle {
  width: 36px;
  height: 36px;
  border-radius: 60px;
  border: 2px solid $borderColor;
}

.green-bg {
  background: $green;
}

.yellow-bg {
  background: $yellow;
}

.red-bg {
  background: $red;
}

.blue-bg {
  background: $blue;
}

.primary-bg {
  background-color: $primary;
}

.el-bg {
  background-color: $elBg;
}

.orange-bg {
  background: $orange;
}

.violet-bg {
  background: $violet;
}

.lavender-bg {
  background: $lavender;
}

.text-white {
  color: $white;
}

.cursor-pointer{
  cursor: pointer;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.mt18 {
  margin-top: 18px;
}

.mt32 {
  margin-top: 32px;
}

.mt28 {
  margin-top: 28px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt40 {
  margin-top: 40px;
}

.mt60 {
  margin-top: 60px;
}

.mt24 {
  margin-top: 24px;
}

.mt12 {
  margin-top: 12px;
}

.mt8 {
  margin-top: 8px;
}

.mt4 {
  margin-top: 4px;
}

.mb2 {
  margin-bottom: 2px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb24 {
  margin-bottom: 24px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb6 {
  margin-bottom: 6px;
}

.text-black {
  color: #000000;
}

.mb40 {
  margin-bottom: 40px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb32 {
  margin-bottom: 32px;
}

.mt20 {
  margin-top: 20px;
}

.mb8 {
  margin-bottom: 8px;
}

.ml2 {
  margin-left: 2px;
}

.font-s10 {
  font-size: 10px;
}

.font-s13 {
  font-size: 13px;
}

.font-s14 {
  font-size: 14px;
}

.font-s18 {
  font-size: 18px;
}

.font-s20 {
  font-size: 20px;
}

.font-s24{
  font-size: 24px;
}

.font-s26 {
  font-size: 26px;
}

.font-s16 {
  font-size: 16px;
}

.font-s6 {
  font-size: 6px;
}

.font-s8 {
  font-size: 8px;
}

.padding-h18 {
  padding-left: 18px;
  padding-right: 18px;
}

.padding-h12 {
  padding-left: 12px;
  padding-right: 12px;
}

.padding-v8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-content-h {
  padding-left: 5.33%;
  padding-right: 5.33%;
}

.padding-v18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.padding-v24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.padding-v20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-v16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.padding-v12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.padding-v6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.padding-h16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-h0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-b40 {
  padding-bottom: 40px;
}

.padding-b24 {
  padding-bottom: 24px;
}

.padding-b10 {
  padding-bottom: 10px;
}

.padding-t40 {
  padding-top: 40px;
}

.padding-t32 {
  padding-top: 32px;
}

.i-align-c {
  align-items: center;
}

.i-align-s {
  align-items: flex-start;
}

.i-align-self-end {
  align-self: flex-end;
}

.i-align-self-start {
  align-self: flex-start;
}

.i-align-unset {
  align-items: unset;
}

.t-align-l {
  text-align: left;
}

.t-align-c {
  text-align: center;
}

.fMontserrat {
  font-family: $fMontserrat;
  font-weight: 600;
}

.fLora{
  font-family: $fLora;
}

.fGolos{
  font-family: $fGolos;
}

.fRoboto {
  font-family: $fRoboto;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-none {
  text-transform: none;
}

.font-w700 {
  font-weight: 700;
}

.font-w600 {
  font-weight: 600;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.gap24 {
  gap: 24px;
}

.gap16 {
  gap: 16px;
}

.gap12 {
  gap: 12px;
}

.gap10 {
  gap: 10px;
}

.gap9 {
  gap: 9px;
}

.gap8 {
  gap: 8px;
}

.gap6 {
  gap: 6px;
}

.gap4 {
  gap: 4px;
}

.gap-unset {
  gap: unset;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute-left0 {
  left: 0;
}

.absolute-right0 {
  right: 0;
}

.height100 {
  height: 100%;
}

.height200{
  height: 200px !important;
}

.height50{
  height: 0px  !important;
}

.height150{
  height: 150px !important;
}

.height20{
  height: 20px !important;
}

.text-color-silver {
  color: $brownishGray;
}

.text-color-blue {
  color: $electricBlue;
}

.text-color-brownishGray{
  color: $brownishGray;
}

.text-color-dark-silver {
  color: #4C556F;
}

.default-border {
  border: 2px solid $borderColor;
}

.bg-with-blur3 {
  background: $inputBg;
  backdrop-filter: blur(3px);
}

.border-radius12 {
  border-radius: 12px;
}

.border-radius8 {
  border-radius: 8px;
}

.overflow-hidden {
  overflow: hidden;
}

.height216 {
  height: 216px;
}

.full-width {
  width: 100%;
}

.width245 {
  width: 245px;
}

.bottom12 {
  bottom: 12px;
}

.width70 {
  width: 70%;
}

.full-height {
  height: 100%;
}

.bg-el-transparent {
  background: rgba(255, 255, 255, 0.02);
}

.yellow-border {
  border: 2px solid #FFD700;
}

.yellow-border1 {
  border: 1px solid #FFD700;
}

.blue-border {
  border: 2px solid $electricBlue;
}

.bg-blur {
  background:  $inputBg;
  backdrop-filter: blur(20px);
}

table tr td:first-child, table tr th:first-child {
  border-left: none;
}

table tr td:last-child, table tr th:last-child {
  border-right: none;
}

table tr th:is(:first-child), table tr th:not(:first-child){
  border-top: none;
}

table tr td:is(:first-child), table tr td:not(:first-child){
  border-bottom: none;
}

table tr td, table tr th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
}

.custom-border-gradient {
  background: radial-gradient(circle at 100% 100%, #172536 0, #172536 16px, transparent 16px) 0% 0%/18px 18px no-repeat,
  radial-gradient(circle at 0 100%, #172536 0, #172536 16px, transparent 16px) 100% 0%/18px 18px no-repeat,
  radial-gradient(circle at 100% 0, #172536 0, #172536 16px, transparent 16px) 0% 100%/18px 18px no-repeat,
  radial-gradient(circle at 0 0, #172536 0, #172536 16px, transparent 16px) 100% 100%/18px 18px no-repeat,
  linear-gradient(#172536, #172536) 50% 50%/calc(100% - 4px) calc(100% - 36px) no-repeat,
  linear-gradient(#172536, #172536) 50% 50%/calc(100% - 36px) calc(100% - 4px) no-repeat,
  linear-gradient(270deg, #374a82 1.04%, #9b7bd0 98.87%);
  border-radius: 18px;
  padding: 26px;
  box-sizing: border-box;
}

.custom-border-gradient2 {
  background: radial-gradient(circle at 100% 100%, #172536 0, #172536 10px, transparent 10px) 0% 0%/12px 12px no-repeat,
  radial-gradient(circle at 0 100%, #172536 0, #172536 10px, transparent 10px) 100% 0%/12px 12px no-repeat,
  radial-gradient(circle at 100% 0, #172536 0, #172536 10px, transparent 10px) 0% 100%/12px 12px no-repeat,
  radial-gradient(circle at 0 0, #172536 0, #172536 10px, transparent 10px) 100% 100%/12px 12px no-repeat,
  linear-gradient(#172536, #172536) 50% 50%/calc(100% - 4px) calc(100% - 24px) no-repeat,
  linear-gradient(#172536, #172536) 50% 50%/calc(100% - 24px) calc(100% - 4px) no-repeat,
  linear-gradient(270deg, #5a8c8d 0%, #9b7bd0 100%);
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
}

textarea {
  all: unset;
  border: 2px solid $borderColor;
  border-radius: 12px;
  height: 220px;
  padding: 16px 16px 16px 16px;
  color: $brownishGray;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: $fMontserrat;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
}

textarea::placeholder {
  color: $brownishGray;
  font-size: 16px;
  font-family: $fRoboto;
  font-weight: 700;
}

.width-fit {
  width: fit-content;
}

.bottom-border {
  border-bottom: 2px solid $borderColor;
}

.place-prediction:last-child {
  border-bottom: none;
}

.button-shadow {
  box-shadow: 0 0 6px 0 #7DF9FF;
}

.pointer {
  cursor: pointer;
}

.suggestion-item {
  list-style-type: none;
  background-color: rgba(18, 33, 51, 1);
  width: 100%;
  font-family: $fRoboto;
  color: $white;
  font-weight: 400;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  border-bottom: 2px solid $borderColor;
}

ul {
  width: 100%;
  border: 2px solid $borderColor;
  position: absolute;
  border-radius: 16px;
  overflow: hidden;
}

.white-space-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2rem; /* Adjust height as needed for two lines */
  line-height: 1rem; /* Adjust line-height for proper spacing */
}

.subtitle-text {
  color: $subtitleColor;
}

.width74 {
  width: 74%;
}

.btn-prediction {
  padding: 14px 22px;
}

.height6 {
  height: 6px;
}

.progress-bar-container-electric-blue.height6 > div:first-child {
  height: 6px !important;
  border-radius: 6px !important;
}

.custom-small-input {
  border: 1px solid $borderColor;
  background:  $inputBg;
  padding: 10px 10px;
  border-radius: 12px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: $brownishGray;
  }

  &:focus {
    border: 1px solid $borderColor;
  }
}

.black-bg {
  background-color: #000;
}

// 
// 
// 
// new variables base css

.gradient-pink-text {
  background: $gradientPink;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-blue-text {
  background: $gradientBlue;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-pink-bg {
  background: $gradientPink;
}

.brownish-gray-text {
  color: $brownishGray;
}
.brownish-gray-bg {
  background-color: $brownishGray;
}

.bg-softLavender { 
   background-color: $softLavender;
}

.text-gray-100 {
  color: $gray100;
}

.text-gray-200 {
  color: $gray200;
}

.text-gray-300 {
  color: $gray300;
}

.text-gray-400 {
  color: $gray400;
}

.text-gray-500 {
  color: $gray500;
}
// 
// 
.bg-gray-100 {
  background-color: $gray100;
}

.bg-gray-200 {
  background-color: $gray200;
}

.bg-gray-300 {
  background-color: $gray300;
}

.bg-gray-400 {
  background-color: $gray400;
}

.bg-gray-500 {
  background-color: $gray500;
}

.bg-lavender {
  background-color: $lavender;
}

.border-pink {
  border-color: $pink;
}

.gradient-border-wrapper {
  background: $gradientBlue;
  padding: 2px;
}

.gradient-border-body {
  border-radius: 6px;
  background: $lavender;
}
.border-radius10{
  border-radius: 10px;
}

.bg-soft-lavender-important {
  background: $softLavender !important;
}

.bg-lavender {
  background-color: $lavender;
}

.swiper-cards .swiper-slide {
  border-radius: 12px;
}
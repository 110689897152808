@import "styles/variables";




.date_demo_wrapper {
  flex-direction: row;
  overflow: hidden;
  color: #fff;
  display: flex;
  margin-top: 40px;
  position: relative;
  justify-content: center;
  align-items: center;
  // @media screen and (max-height: 667px) {
  //   margin-top: 30px;
  // }

  // @media screen and (max-height: 480px) {
  //   margin-top: 20px;
  // }
}

.date_flicking {
  overflow: visible;
  box-sizing: content-box;
  height: 250px;
  perspective: 700px;
  position: relative;
  // transform-style: preserve-3d;
  width: 100px;
  text-align: center;

  // @media screen and (max-height: 667px) {
  //   height: 250px;
  // }

  // @media screen and (max-height: 480px) {
  //   height: 150px;
  // }
}


.date_flicking>.flicking-camera {
  transform-style: preserve-3d;
}

.date_panel {
  align-items: center;
  height: 42px;
  margin-bottom: 10px;
  backface-visibility: hidden;
  font-size: 18px;
  color: $brownishGray;
  font-weight: 500;
  font-family: $fMontserrat;

  // @media screen and (max-height: 667px) {
  //   height: 32px;
  // }

  // @media screen and (max-height: 480px) {
  //   height: 20px;
  // }
}

.date_panel_container {
  z-index: -3;
}

.date_panel_border {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 100px;
  left: 5px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;

  // @media screen and (max-height: 667px) {
  //   top: 80px;
  // }

  // @media screen and (max-height: 480px) {
  //   top: 60px;
  // }
}



.shadow_top {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  background: #E1DEF805;
  z-index: 10;
  backdrop-filter: blur(2px);

}

.shadow_bottom {
  width: 100%;
  height: 45px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #E1DEF805;
  z-index: 10;
  backdrop-filter: blur(2px);

  // @media screen and (max-height: 667px) {
  //   height: 25px;
  // }

  // @media screen and (max-height: 480px) {
  //   height: 35px !important;
  // }
}

.bottom_container_absolute {
  position: absolute;
  width: 100%;
  bottom: 0;
}


.has-text-white {
  color: #fff;
}
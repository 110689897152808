@import "src/styles/variables";


.container {
    height: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    // background-color: $blue;
}

.imageContainer{
    height: 50%;
}



.btnContainer {
    width: 100%;
}
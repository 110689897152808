@import "styles/variables";

.date_demo_wrapper {
  flex-direction: row;
  overflow: hidden;
  color: #fff;
  display: flex;
  margin-top: 40px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.date_flicking {
  overflow: visible;
  box-sizing: content-box;
  height: 250px;
  perspective: 700px;
  position: relative;
  // transform-style: preserve-3d;
  width: 100px;
  text-align: center;
}


.date_flicking>.flicking-camera {
  transform-style: preserve-3d;
}

.date_panel {
  align-items: center;
  height: 42px;
  margin-bottom: 10px;
  backface-visibility: hidden;
  font-size: 18px;
  color: $brownishGray;
  font-weight: 500;
  font-family: $fMontserrat;
}

.date_panel_container {
  z-index: -3;
}

.date_panel_border_1 {
  width: 70px;
  height: 30px;
  position: absolute;
  top: 60px;
  left: 5px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;
}

.date_panel_border_2 {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 60px;
  left: 160px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;
}
.date_panel_border_3 {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 60px;
  left: 160px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;
}



.shadow_top {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: #E1DEF805;
  z-index: 10;
  backdrop-filter: blur(2px);

}

.shadow_bottom {
  width: 100%;
  height: 45px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #E1DEF805;
  z-index: 10;
  backdrop-filter: blur(2px);
}

.bottom_container_absolute {
  position: absolute;
  width: 100%;
  bottom: 0;
}


.has-text-white {
  color: #fff;
}